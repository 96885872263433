import { Header } from "@~/common/components"
import { ScrollToTop } from "@~/common/components/navigation/ScrollToTop"
import { InfoSection } from "media/components/common/InfoSection"
import { FC, ReactElement, ReactNode } from "react"
import LazyHydrate from "react-lazy-hydration"
import { AppPageLayout, AppPageLayoutProps } from "../AppPageLayout"

export type MediaPageLayoutProps = Omit<
  AppPageLayoutProps,
  "header" | "footer"
> & {
  noInfo?: boolean
  noScrollToTop?: boolean
}

export const MediaPageLayout: FC<MediaPageLayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <AppPageLayout
      {...props}
      header={<Header zIndex={2} displayMediaSearchInput />}
    >
      {children}
      {!props.noInfo && <InfoSection />}
      {!props.noScrollToTop && (
        <LazyHydrate whenIdle>
          <ScrollToTop />
        </LazyHydrate>
      )}
    </AppPageLayout>
  )
}

export const createGetMediaPageLayout = (
  layoutProps?: MediaPageLayoutProps
): ((page: ReactElement) => ReactNode) => {
  return function getLayout(page: ReactElement): ReactNode {
    return <MediaPageLayout {...layoutProps}>{page}</MediaPageLayout>
  }
}
