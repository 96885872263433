import {
  AspectRatio,
  Box,
  chakra,
  HStack,
  Image,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { Visible } from "@~/common/components/system"
import { imgixURL } from "@~/common/lib/imgix"
import { Author, Entry } from "@~/common/lib/microcms/media"
import { Category } from "@~/common/lib/microcms/media/types/categories"
import { Tag } from "@~/common/lib/microcms/media/types/tags"
import { Overwrite } from "@~/common/types/util"
import { lightFormat } from "date-fns"
import { getEntryPagePath } from "media/lib/urls"
import NextLink from "next/link"

export type ArticleCardProps = {
  entry: Overwrite<
    Pick<Entry, "id" | "title" | "eyecatch" | "publishedAt" | "createdAt">,
    {
      authors: Pick<Author, "name">[]
      category?: Pick<Category, "id" | "name">
      tags?: Pick<Tag, "id" | "name">[]
    }
  >

  /** 大きなエリアに描画する可能性がある
   * - 画像の取得サイズを大きめにします
   */
  possibleLargerWindow?: boolean
  /**
   * 表示モードを切り替えます
   * NOTE: 下層ページではSPでの記事表示数を多くしたい事が多いので、専用モードのみ実装します
   */
  viewMode?: "sp-simple"
} & LinkBoxProps

const LayoutPropsWithViewMode = {
  "sp-simple": {
    cardItemWrapper: {
      direction: { base: "row", md: "column" },
    },
    cardEyecatch: {
      order: { base: 2, md: 1 },
      w: { base: "130px", md: "full" },
      h: { base: "68px", md: "auto" },
      flex: { base: "none", md: 1 },
    },
    cardTitle: {
      order: { base: 1, md: 2 },
    },
    cardTags: {
      order: { base: 2, md: 3 },
    },
    cardMeta: {
      order: { base: 1, md: 2 },
    },
    cardInfo: {
      order: { base: 3, md: 1 },
    },
    cardInfoDate: {
      display: { base: "none", md: "block" },
    },
    cardInfoAuthor: {
      ml: { base: 1, md: "auto" },
    },
  },
} as const

export const ArticleCard = ({
  entry,
  possibleLargerWindow,
  viewMode,
  ...props
}: ArticleCardProps) => {
  const { title, eyecatch, authors, category, tags, publishedAt, createdAt } =
    entry

  return (
    <LinkBox
      as="article"
      minW={{ base: "165px", lg: "254px" }}
      bgColor="white"
      {...props}
    >
      <Stack spacing={3} justify="space-between" h="full">
        <Stack
          spacing={2}
          {...(LayoutPropsWithViewMode[viewMode || ""]?.cardItemWrapper || {})}
        >
          <AspectRatio
            ratio={{ base: 145 / 76.3, md: 245 / 131.5 }}
            {...(LayoutPropsWithViewMode[viewMode || ""]?.cardEyecatch || {})}
          >
            <Image
              src={imgixURL(
                eyecatch?.url,
                `w=${possibleLargerWindow ? 1200 : 245 * 2}&fit=crop&crop=faces`
              )}
              alt={title}
              objectFit="cover"
              borderRadius={{ base: "5px", md: "m" }}
              ignoreFallback={!!eyecatch}
              fallback={
                <Box
                  bgColor="moneiro-color-fill-gray-basic"
                  borderRadius={{ base: "5px", md: "m" }}
                />
              }
            />
          </AspectRatio>
          <VStack
            spacing={0}
            {...(LayoutPropsWithViewMode[viewMode || ""]?.cardMeta || {})}
          >
            <HStack
              w="full"
              spacing={1}
              mb="6px"
              {...(LayoutPropsWithViewMode[viewMode || ""]?.cardInfo || {})}
            >
              {category && (
                <Text
                  color="white"
                  bgColor="ui.main"
                  textStyle="f10"
                  fontWeight={600}
                  lineHeight="18px"
                  px={{ base: 1, md: 2 }}
                  rounded="4px"
                  flex="none"
                >
                  {category.name}
                </Text>
              )}
              <chakra.div
                fontSize="10px"
                color="text.gray"
                ml={1}
                {...(LayoutPropsWithViewMode[viewMode || ""]?.cardInfoDate ||
                  {})}
              >
                {lightFormat(new Date(publishedAt ?? createdAt), "yyyy/MM/dd")}
              </chakra.div>
              <chakra.div
                fontWeight="500"
                fontSize="10px"
                color="text.gray"
                {...(LayoutPropsWithViewMode[viewMode || ""]
                  ?.cardInfoAuthor || {
                  ml: "auto",
                })}
              >
                <Visible baseOnly>
                  {authors.map((author) => author.name).join("｜")}
                </Visible>
                <Visible mdOnly>{authors[0].name}</Visible>
              </chakra.div>
            </HStack>
            <chakra.h3
              textStyle={{ base: "13-bold", md: "14-bold" }}
              mb={{ base: 0, md: "4px" }}
              {...(LayoutPropsWithViewMode[viewMode || ""]?.cardTitle || {})}
            >
              <NextLink
                href={getEntryPagePath(entry).$url()}
                passHref
                legacyBehavior
              >
                <LinkOverlay>{title}</LinkOverlay>
              </NextLink>
            </chakra.h3>
            <Wrap
              spacingX="8px"
              spacingY={0}
              w="full"
              mb={{ base: "4px", md: 0 }}
              {...(LayoutPropsWithViewMode[viewMode || ""]?.cardTags || {})}
            >
              {tags &&
                tags.map((tag) => {
                  return (
                    <WrapItem
                      fontSize={{ base: "10px", md: "11px" }}
                      color="moneiro-color-main-orange"
                      key={tag.id}
                    >
                      #{tag.name}
                    </WrapItem>
                  )
                })}
            </Wrap>
          </VStack>
        </Stack>
      </Stack>
    </LinkBox>
  )
}

export const EmphasizedArticleCard = ({
  entry,
  ...props
}: ArticleCardProps) => {
  const { title, eyecatch, authors, category, tags, publishedAt, createdAt } =
    entry

  return (
    <LinkBox
      as="article"
      minW={{ base: "165px", lg: "254px" }}
      bgColor="white"
      {...props}
    >
      <Stack direction={{ base: "column", md: "row" }}>
        <AspectRatio
          ratio={{ base: 145 / 76.3, md: 245 / 131.5 }}
          w={{ base: "full", md: "464px" }}
          h={{ base: "auto", md: "244px" }}
          flex={"none"}
        >
          <Image
            src={imgixURL(eyecatch?.url, `w=${600 * 2}&fit=crop&crop=faces`)}
            alt={title}
            objectFit="cover"
            borderRadius="m"
            ignoreFallback={!!eyecatch}
            fallback={
              <Box
                bgColor="moneiro-color-fill-gray-basic"
                borderRadius={{ base: "5px", md: "m" }}
              />
            }
            marginRight={{ base: 0, md: 1 }}
          />
        </AspectRatio>

        <VStack spacing={0} marginLeft={{ base: 0, md: 2 }}>
          {/* カテゴリ・作成日付・執筆者 */}
          <HStack
            w="full"
            spacing={1}
            order={{ base: 3, md: 1 }}
            marginBottom={{ base: 0, md: 3 }}
          >
            {category && (
              <Text
                color="white"
                bgColor="ui.main"
                textStyle="f10"
                fontWeight={600}
                lineHeight="18px"
                px={{ base: 1, md: 2 }}
                rounded="4px"
              >
                {category.name}
              </Text>
            )}
            <chakra.div
              fontSize="10px"
              color="text.gray"
              ml={{ base: "auto", md: 1 }}
              order={{ base: 3, md: 2 }}
            >
              {lightFormat(new Date(publishedAt ?? createdAt), "yyyy/MM/dd")}
            </chakra.div>
            <chakra.div
              fontWeight="500"
              fontSize="10px"
              color="text.gray"
              ml={{ base: 1, md: "auto" }}
              order={{ base: 2, md: 3 }}
              marginRight={8}
            >
              <Visible baseOnly>
                {authors.map((author) => author.name).join("｜")}
              </Visible>
              <Visible mdOnly>{authors[0].name}</Visible>
            </chakra.div>
          </HStack>

          {/* タイトル */}
          <chakra.h3
            textStyle={{ base: "13-bold", md: "24-bold" }}
            order={{ base: 1, md: 2 }}
            mb={{ base: 0, md: "4px" }}
            margin={0}
            w={"full"}
          >
            <NextLink
              href={getEntryPagePath(entry).$url()}
              passHref
              legacyBehavior
            >
              <LinkOverlay>{title}</LinkOverlay>
            </NextLink>
          </chakra.h3>

          {/* タグ */}
          <Wrap
            spacingX="8px"
            spacingY={0}
            w="full"
            order={{ base: 2, md: 3 }}
            mb={{ base: "4px", md: 0 }}
            marginTop={{ base: 1, md: 2 }}
          >
            {tags &&
              tags.map((tag) => {
                return (
                  <WrapItem
                    fontWeight="700"
                    fontSize={{ base: "10px", md: "13px" }}
                    color="moneiro-color-main-orange"
                    key={tag.id}
                  >
                    #{tag.name}
                  </WrapItem>
                )
              })}
          </Wrap>
        </VStack>
      </Stack>
    </LinkBox>
  )
}
