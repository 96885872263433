import { chakra, Fade, IconButton, Tooltip } from "@chakra-ui/react"
import { animate, AnimationOptions } from "framer-motion"
import React, { useCallback } from "react"
import { AiOutlineToTop } from "react-icons/ai"
import { NoSSR } from "../system"

export type ScrollToTopProps = {
  right?: number
  bottom?: number
}

/** 上にスクロールするボタンを画面右下に表示します
 *
 * - 画面サイズ以上のスクロール位置の場合はアニメーション有り
 * - 画面サイズ程度のスクロール位置の場合はアニメーション無し
 */
export const ScrollToTop = (props: ScrollToTopProps) => {
  return (
    <NoSSR>
      <chakra.div
        position="fixed"
        zIndex="sticky"
        bottom={2}
        right={2}
        {...props}
      >
        <ScrollToTopButton />
      </chakra.div>
    </NoSSR>
  )
}

const ScrollToTopButton = (props: {}) => {
  const onClickButton = useCallback(() => {
    smoothScrollToElement("top")
  }, [])
  return (
    <Fade in={true} delay={0.3}>
      <Tooltip label="上にスクロール" placement="top-start">
        <IconButton
          boxShadow="base"
          aria-label="上にスクロール"
          onClick={onClickButton}
          icon={<AiOutlineToTop fontSize="22px" />}
        />
      </Tooltip>
    </Fade>
  )
}

export type SmoothScrollToElementOption = Omit<
  AnimationOptions<number>,
  "onUpdate"
> & {
  scrollYBuffer?: number
}

export const smoothScrollToElement = (
  elem: HTMLElement | { y: number } | null | undefined | "top",
  { scrollYBuffer = 50, ...options }: SmoothScrollToElementOption = {
    scrollYBuffer: 50,
    duration: 0.2,
  }
): ReturnType<typeof animate> | undefined => {
  if (!elem) return

  // アニメーションしつつスクロールする
  // そもそもそこまでスクロールしていないならアニメーション無しでスクロールする
  let targetY: number
  if (elem === "top") {
    targetY = 0 - scrollYBuffer
  } else if (typeof elem === "object" && "y" in elem) {
    targetY = elem.y - scrollYBuffer
  } else {
    targetY = elem.getBoundingClientRect().top + window.scrollY - scrollYBuffer
  }

  const distance = Math.abs(window.scrollY - targetY)
  if (distance > window.screen.height) {
    return animate(window.scrollY, targetY, {
      duration: 0.2,
      ...options,
      onUpdate: (v) => window.scrollTo(0, v),
    })
  } else {
    window.scrollTo(0, targetY)
    return {
      stop: () => {},
      isAnimating: () => false,
    }
  }
}
